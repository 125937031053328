<template>
  <v-container fill-height fluid class="background">
    <v-row justify="center" align="center">
      <!-- <v-col> -->
      <v-card elevation="8" outlined min-width="350px" max-width="450px">
        <router-link :to="{ name: 'Home' }">
          <v-img src="/img/bear_icon.png" max-height="200px" contain></v-img>
        </router-link>
        <v-card-text>
          <v-row align="center" justify="center">
            <v-btn-toggle v-model="formSelection" mandatory color="red" class="justify-center">
              <v-btn block> Login </v-btn>
              <v-btn block> Register </v-btn>
            </v-btn-toggle>
          </v-row>
        </v-card-text>
        <v-tabs-items v-model="formSelection">
          <v-tab-item :key="0">
            <v-form ref="login" @submit.prevent="login()">
              <v-card-actions>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="details.email"
                      name="email"
                      label="E-mail"
                      autocomplete="email"
                      filled
                      color="primary"
                      :error-messages="errorFor('email')"
                      @keyup.enter="login()"
                    />
                    <v-text-field
                      v-model="details.password"
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autocomplete="current_password"
                      filled
                      color="primary"
                      :error-messages="errorFor('password')"
                      @keyup.enter="login()"
                    />
                  </v-col>
                </v-row>
              </v-card-actions>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-4" x-large block dark @click="login()">submit</v-btn>
              </v-card-actions>
            </v-form>
          </v-tab-item>
          <v-tab-item :key="1">
            <v-form ref="registration" @submit.prevent="register()">
              <v-card-actions>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="details.name"
                      name="name"
                      label="Name"
                      autocomplete="name"
                      filled
                      color="primary"
                      :error-messages="errorFor('name')"
                      @keyup.enter="register()"
                    />
                    <v-text-field
                      v-model="details.email"
                      name="email"
                      label="E-mail"
                      filled
                      color="primary"
                      :error-messages="errorFor('email')"
                      @keyup.enter="register()"
                    />
                    <v-text-field
                      v-model="details.password"
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autocomplete="new_password"
                      filled
                      color="primary"
                      :error-messages="errorFor('password')"
                      @keyup.enter="register()"
                    />
                    <v-text-field
                      v-model="details.password_confirmation"
                      name="password_confirmation"
                      label="Confirm Password"
                      type="password"
                      id="password_confirmation"
                      autocomplete="new_password"
                      filled
                      color="primary"
                      @keyup.enter="register()"
                    />
                  </v-col>
                </v-row>
              </v-card-actions>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-4" x-large block dark @click="register()">submit</v-btn>
              </v-card-actions>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <!-- </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import errors from '@/shared/mixins/errors'

export default {
  name: 'LoginRegister',
  mixins: [errors],
  data() {
    return {
      formSelection: 0,
      details: {
        name: null,
        email: null,
        password: null,
        password_confirmation: null
      }
    }
  },
  computed: {
    title() {
      return this.formSelection === 0 ? 'Login' : 'Register'
    },
    rules() {
      return {
        required: (value) => !!value || 'Required',
        email: (value) => this.validateEmailFormat(value) || 'Please enter a valid e-mail address'
      }
    }
  },
  mounted() {
    this.$store.commit('setErrors', {})
  },
  methods: {
    ...mapActions('auth', ['sendRegisterRequest', 'sendLoginRequest']),
    validateEmailFormat: function (value) {
      const re =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      return re.test(value)
    },
    login: function () {
      if (this.$refs.login.validate()) {
        try {
          this.sendLoginRequest(this.details).then(() => {
            this.$router.push({ name: 'Home' })
          })
        } catch (error) {
          console.log(error)
          this.$refs.registration.resetValidation()
        }
      }
    },
    register: function () {
      if (this.$refs.registration.validate()) {
        this.$store.commit('setErrors', {})
        try {
          this.sendRegisterRequest(this.details).then(() => {
            this.$router.push({ name: 'ProfileIndex' })
          })
        } catch (error) {
          console.log(error)
          this.$refs.registration.resetValidation()
        }
      }
    }
  }
}
</script>

<style>
.background {
  background: rgb(170, 28, 28);
  background: linear-gradient(155deg, rgba(170, 28, 28, 1) 35%, rgba(92, 20, 20, 1) 100%);
}
</style>
