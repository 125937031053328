import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['errors'])
  },
  methods: {
    errorFor(field) {
      return this.errors !== {} && this.errors[field] ? this.errors[field] : null
    }
  }
}
